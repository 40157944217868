<template>
  <v-container id="chef-profile">

    <TopBar
      @show-select-address="showSelectAddress = true"
      @show-nav-drawer="showNavDrawer = true"
    />

    <div class="my-page-content">
      <v-row>
        <v-col cols="12" class="text-left">
          <v-btn
            icon
            depressed
            width="17"
            height="8"
            @click="goToMainFeed"
          >
            <v-icon size="17">{{back_arrow}}</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="d-flex align-center justify-center">
          <img
            class="chef-avatar"
            alt="user"
            :src="publicPath + chefDetails.avatar"
          >
        </v-col>
        <v-col cols="12" class="text-center">
          <p class="chef-name">
            {{chefDetails.chefName}}
          </p>
          <!-- Reviews to be added to another version -->
          <!-- <p class="chef-avg">{{chefDetails.review}}</p> -->
        </v-col>
      </v-row>

      <v-row class="mt-0">
        <v-col cols="12" class="text-right">
          <v-btn
            depressed
            class="dishes-btn"
            height="32"
            @click="filterChefs"
          >
            Dishes
          </v-btn>
        </v-col>
      </v-row>

      <div
        v-for="(item, index) in chefDetails.content_en"
        :key="'content-'+index"
      >
        <v-row class="chef-image">
          <v-col cols="12" class="pa-0">
            <v-img
              contain
              class="text-right"
              position="top center"
              :src="getChefImage(item)"
            >
            </v-img>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <div class="title-2">{{item.title}}</div>
          </v-col>
          <v-col cols="12">
            <div class="chef-story">{{item.description}}</div>
          </v-col>
        </v-row>
      </div>

      <div class="latest-updates">
        <v-row class="mt-5">
          <v-col cols="12">
            <div class="latest-updates-title">
              USUALLY SERVES
            </div>
          </v-col>
          <v-col cols="12">
            <v-chip
              v-for="(item,index) in chefDetails.tags"
              :key="'chef-tag-'+index"
              class="serves-chip"
              :color="item.color"
            >
              {{getTagName(item)}}
            </v-chip>
          </v-col>
        </v-row>
      </div>
    </div>

    <SelectAddressOverlay
      :visible="showSelectAddress"
      @close="showSelectAddress = false"
    />

    <SignInModal
      :visible="showSignInModal"
      @close="showSignInModal = false"
    />

    <NavigationDrawer
      :visible="showNavDrawer"
      @close="showNavDrawer = false"
      @open-sign-in-modal="openSignInModal"
    />
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import TopBar from '@/components/Navigation/TopBar';
  import SelectAddressOverlay from '@/components/MainFeed/SelectAddressOverlay';
  import NavigationDrawer from '@/components/Navigation/NavigationDrawer';
  import SignInModal from '@/components/Auth/SignInModal.vue';

  export default {
    name: 'ChefProfile',
    components: {
      TopBar,
      SelectAddressOverlay,
      NavigationDrawer,
      SignInModal
    },
    computed: {
      ...mapGetters([
        "chefDetails",
        "allDishes"
      ]),
      isChefDetailsEmpty() {
        return Object.keys(this.chefDetails).length === 0;
      }
    },
    data: () => ({
      back_arrow: "$vuetify.icons.back_arrow",
      publicPath: process.env.VUE_APP_BASE_URL,
      showSelectAddress: false,
      showNavDrawer: false,
      showSignInModal: false
    }),
    created() {
      if(localStorage.getItem('chefId') && this.isChefDetailsEmpty) {
        let chefId = localStorage.getItem('chefId');
        this.getChefDetails(chefId);
      } else if (localStorage.getItem('chefId') == null) {
        this.goToMainFeed();
      }
    },
    methods: {
      ...mapActions([
        "setDishes",
        "setSelectedChefs",
        "getChefDetails"
      ]),
      goToMainFeed() {
        this.$router.push({name: 'menu'});
      },
      filterChefs() {
        let chefId = this.chefDetails.id;
        let result = this.allDishes.filter((item) => {
          return item.chefId === chefId;
        });
        this.setDishes(result).then(() => {
          this.setSelectedChefs([chefId]).then(() => {
            this.goToMainFeed();
          });
        });
      },
      getChefImage(item) {
        let url = '';
        if (item.photo != null) {
          url = this.publicPath + item.photo.url.replace(" ", "%20");
        }
        
        return url;
      },
      getTagName(item) {
        let result = '';
        if (item.abbreviation != null) {
          result = item.abbreviation;
        } else {
          result = item.name.toLowerCase();
        }
        return result;
      },
      openSignInModal() {
        this.showNavDrawer = false;
        this.showSignInModal = true;
      },
    }
  };
</script>

<style type="text/css" scoped>
  .slide-menu-container {
    overflow: auto;
    white-space: nowrap;
    width: 100vw;
    padding: 16px 0;
    margin-left: -22px;
  }

  .slide-menu-container::-webkit-scrollbar {
    display: none;
  }

  .slide-menu-item {
    display: inline-block;
    position: relative;
    text-align: center;
    margin: 0 5px;
  }

  .slide-menu-item:first-child {
    margin-left: 10px;
  }

  .slide-menu-item:last-child {
    margin-right: 10px;
  }
</style>

<style type="text/css">
  #chef-profile {
    position: relative;
    padding: 20px;
    background: rgba(196, 243, 255, 0.3);
  }

  #chef-profile .top-bar {
    background: transparent;
  }

  #chef-profile .chef-name {
    margin: 0 0 2px 12px;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
  }

  #chef-profile .chef-avg {
    margin: 0 0 0 12px;
    font-weight: 400;
    font-size: 9px;
    line-height: 120%;
  }

  .chef-avatar {
    border-radius: 50%;
  }

  .chef-image {
    margin: 12px -20px 0;
  }

  .chef-image .v-image {
    max-height: 500px;
  }

  .chef-image .v-image {
    max-height: 500px;
  }

  .moto {
    margin-top: 26px;
    font-weight: 200;
    font-size: 16px;
    line-height: 150%;
    font-style: italic;
  }

  #my-app .dishes-btn {
    background: #C4F3FF;
    border-radius: 43px;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #001254;
  }

  .chef-story {
    padding-left: 60px;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
  }

  .section-image {
    margin: 60px -20px 24px;
  }

  #chef-profile .latest-updates {
    margin: 80px -20px -20px;
    padding: 20px 20px 40px;
    background: #001254;
  }

  .latest-updates-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #F2F2F2;
  }

  #chef-profile .serves-chip {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #001254;
    border-radius: 30px;
    height: 38px;
    margin-bottom: 8px;
  }

  .serves-chip:not(:last-child) {
    margin-right: 12px;
  }
</style>